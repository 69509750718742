import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts';
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';

interface LineChartProps {
  title: string;
  labels: string[];
  dataPoints: number[];
  className?: string;
  color?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  title,
  labels,
  dataPoints,
  className = '',
  color = 'primary',
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

  const getChartOptions = (height: number, color: string): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-500');
    const borderColor = getCSSVariableValue('--kt-gray-200');
    const baseColor = getCSSVariableValue(`--kt-${color}`);
    const secondaryColor = getCSSVariableValue('--kt-info');

    return {
      series: [
        {
          name: '',
          data: dataPoints,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: labels,
        axisBorder: {show: false},
        axisTicks: {show: false},
        labels: {style: {colors: labelColor, fontSize: '12px'}},
      },
      yaxis: {
        labels: {style: {colors: labelColor, fontSize: '12px'}},
      },
      fill: {type: 'solid', opacity: 0.8},
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {lines: {show: true}},
      },
      markers: {
        size: 5,
        colors: [baseColor],
        strokeColors: '#ffffff',
        strokeWidth: 2,
      },
      tooltip: {
        style: {fontSize: '12px'},
        y: {formatter: (val: number) => val.toString()},
      },
      colors: [baseColor],
    };
  };

  useEffect(() => {
    if (chartRef.current) {
      const height = parseInt(window.getComputedStyle(chartRef.current).height);
      const chart = new ApexCharts(chartRef.current, getChartOptions(height, color));

      // Render the chart
      chart.render();

      // Cleanup chart instance on component unmount or dependency change
      return () => {
        chart.destroy();
      };
    }
  }, [labels, dataPoints, mode]); // Trigger useEffect when labels, dataPoints, or theme mode changes

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body'>
        <div ref={chartRef} style={{height: '350px'}}></div>
      </div>
    </div>
  );
};

export default LineChart;
