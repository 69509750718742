import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {OrgChartNode} from './_models';
import {getOrgChartData} from './_requests';

const departmentColors: {[key: string]: string} = {
  'After Hours': '#4e79a7',
  'Call Centre': '#f28e2c',
  'CMB Sales': '#e15759',
  'Customer Relations': '#76b7b2',
  Executive: '#59a14f',
  'Front Office': '#9c755f',
  'Group Finance': '#edc948',
  'Group HR & Admin': '#bab0ab',
  Holidays: '#b07aa1',
  HR: '#ff9da7',
  Marketing: '#76b7b2',
  'MEL Call Centre': '#59a14f',
  'MEL Office': '#ff9da7',
  Online: '#4e79a7',
  Operations: '#f28e2c',
  'Outsourced Accounting': '#edc948',
  'Outsourced Technology': '#bab0ab',
  Sales: '#e15759',
  'Sales Support': '#76b7b2',
};

export const OrgChartComponent: React.FC = () => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [orgData, setOrgData] = useState<OrgChartNode | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedData = await getOrgChartData();
        setOrgData(fetchedData);
      } catch (error) {
        console.error('Error fetching organizational chart data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!svgRef.current || !orgData) return;

    const svgElement = svgRef.current;
    const containerWidth = svgElement.clientWidth;
    const containerHeight = svgElement.clientHeight;

    const margin = {top: 300, right: 300, bottom: 300, left: 300}; // Increased margins for spacing

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', '100%')
      .style('background-color', 'white');

    svg.selectAll('*').remove();

    const g = svg.append('g');

    const zoom = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.1, 2])
      .on('zoom', (event) => g.attr('transform', event.transform));

    svg.call(zoom);

    const root = d3.hierarchy<OrgChartNode>(orgData);

    const treeLayout = d3
      .tree<OrgChartNode>()
      .size([
        containerWidth * 3, // Increased width for horizontal spacing
        containerHeight * 3, // Increased height for vertical spacing
      ])
      .separation((a, b) => {
        // Larger horizontal and vertical spacing
        const horizontalSpacing = a.parent === b.parent ? 10 : 15; // Siblings vs. other nodes
        const verticalSpacing = 20; // Larger vertical space between levels
        return horizontalSpacing + verticalSpacing;
      });

    const treeData = treeLayout(root);

    g.selectAll('.link')
      .data(treeData.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr(
        'd',
        d3
          .linkVertical<d3.HierarchyPointLink<OrgChartNode>, d3.HierarchyPointNode<OrgChartNode>>()
          .x((d) => d.x)
          .y((d) => d.y)
      )
      .attr('fill', 'none')
      .attr('stroke', '#ccc');

    const node = g
      .selectAll('.node')
      .data(treeData.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    // Add department circle
    node
      .append('circle')
      .attr('r', 20)
      .attr('fill', (d) => departmentColors[d.data.department_name] || '#999')
      .attr('stroke', '#333');

    // Add department name above the circle
    node
      .append('text')
      .attr('dy', '-3em') // Position above the circle
      .style('text-anchor', 'middle')
      .style('font-size', '2.5em') // Slightly larger font size
      .text((d) => d.data.department_name);

    // Add manager's name below the circle
    node
      .append('text')
      .attr('dy', '2em') // Position below the circle
      .style('text-anchor', 'middle')
      .style('font-size', '1.5em') // Slightly larger font size
      .style('font-weight', 'bold') // Bold for emphasis
      .text((d) =>
        d.data.manager
          ? `${d.data.manager.first_name} ${d.data.manager.last_name} (${d.data.manager.position_name})`
          : ''
      );

    // Add some space between the manager and staff
    const staffStartOffset = 5; // Large spacing between manager and staff

    // Display staff members below the manager
    node
      .selectAll('.staff-name')
      .data((d) => d.data.users || [])
      .enter()
      .append('text')
      .attr('class', 'staff-name')
      .attr('dy', (d, i) => `${staffStartOffset + i * 2.5}em`) // Spaced vertically for clarity
      .style('text-anchor', 'middle')
      .style('font-size', '1.3em') // Slightly larger font size for staff
      .style('fill', '#666')
      .text((user) => `${user.first_name} ${user.last_name} (${user.position_name})`);

    const bounds = g.node()?.getBBox();
    if (bounds) {
      const width = bounds.width + margin.left + margin.right;
      const height = bounds.height + margin.top + margin.bottom;

      const scale = Math.min(containerWidth / width, containerHeight / height);
      const translateX = (containerWidth - width * scale) / 2;
      const translateY = (containerHeight - height * scale) / 2;

      svg.call(zoom.transform, d3.zoomIdentity.translate(translateX, translateY).scale(scale));
    }
  }, [orgData]);

  return (
    <>
      {loading ? (
        <div>Loading organizational chart...</div>
      ) : orgData ? (
        <svg ref={svgRef} style={{width: '100%', height: '100%'}}></svg>
      ) : (
        <div>No organization data available</div>
      )}
    </>
  );
};
