import React from 'react';
import MiniProgressChart from './MiniProgressChart';

interface StaffProgress {
  name: string;
  email: string;
  performance: {
    ticketsSold: number;
    profitMade: number;
  };
}

interface StaffProgressTableProps {
  staffData: StaffProgress[];
  className?: string;
}

const StaffProgressTable: React.FC<StaffProgressTableProps> = ({staffData, className}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Team Member Progress</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Monthly targets for deals and profits
          </span>
        </h3>
      </div>
      <div className='card-body'>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th>Staff Name</th>
              <th>Tickets Progress</th>
              <th>Profit Progress</th>
            </tr>
          </thead>
          <tbody>
            {staffData.map((staff) => (
              <tr key={staff.email}>
                <td>{staff.name}</td>
                <td style={{width: '45%'}}>
                  <MiniProgressChart
                    className='card-xl-stretch mb-5 mb-xl-8'
                    current={staff.performance.ticketsSold}
                  />
                </td>
                <td style={{width: '45%'}}>
                  <MiniProgressChart
                    className='card-xl-stretch mb-5 mb-xl-8'
                    current={staff.performance.profitMade}
                    currency={true}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StaffProgressTable;
