import React from 'react';

type ProgressChartProps = {
  className?: string;
  current: number; // Current value (tickets sold or profit amount)
  currency?: boolean; // To decide whether to show currency or not
};

const ProgressChart: React.FC<ProgressChartProps> = ({className, current, currency}) => {
  return (
    <div className={` ${className} `}>
      <div className=''>
        <div className='col bg-light-dark px-6 py-8 rounded-2 me-7'>
          <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2 text-warning'>
            {currency ? `$${current}` : current}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
