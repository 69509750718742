import React from 'react';

type ProgressChartProps = {
  className?: string;
  title: string;
  description: string;
  current: number; // Current value (tickets sold or profit amount)
  nextTarget: number;
  currentIncentive: number;
  nextIncentive: number;
  type: string;
  targets: Array<{
    target: number;
    incentive: number;
  }>;
  currency?: boolean; // To decide whether to show currency or not
};

const ProgressChart: React.FC<ProgressChartProps> = ({
  className,
  title,
  description,
  current,
  targets,
  currency,
  nextTarget,
  type,
  currentIncentive,
  nextIncentive,
}) => {
  console.log('targets ::: ', targets);
  console.log('targets.length ::: ', targets.length);

  console.log('targets.length - 1 ::: ', targets.length - 1);
  const maxTarget = targets[targets.length - 1].target;
  const progress = Math.min((current / maxTarget) * 100, 100);

  // Determine the color of the progress bar based on the current progress
  const getProgressBarColor = () => {
    if (current >= targets[3]?.target) {
      return 'bg-success'; // 4th target or beyond
    } else if (current >= targets[2]?.target) {
      return 'bg-info'; // 3rd target
    } else if (current >= targets[1]?.target) {
      return 'bg-warning'; // 2nd target
    } else if (current >= targets[0]?.target) {
      return 'bg-warning'; // 1st target
    } else {
      return 'bg-danger'; // Below 1st target
    }
  };

  return (
    <div className={`card ${className} pb-10`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='badge badge-light-success fs-base'>Current {type} incentive</span>
            <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2 '>${currentIncentive}</span>
          </div>
          <div className='d-flex align-items-center mt-5'>
            <span className='badge badge-light-success fs-base'>Only</span>
            <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2 text-warning'>
              {currency ? `$${nextTarget}` : nextTarget}
            </span>
            <span className='badge badge-light-success fs-base'>
              {currency ? `more profit` : `more tickets`} to get
            </span>
            <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2 text-success'>${nextIncentive}</span>
          </div>
        </div>
      </div>
      <div className='card-body my-3'>
        <div className='col bg-light-dark px-6 py-8 rounded-2 me-7'>
          <div className='card-title d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                {currency ? `$${current}` : current}
              </span>
              <span className='badge badge-light-success fs-base'>
                Only {currency ? `$${nextTarget} more profit` : `${nextTarget} more tickets`} to the
                next target
              </span>
            </div>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
          </div>
          <div className='position-relative mt-4'>
            <div className='progress h-10px'>
              <div
                className={`progress-bar ${getProgressBarColor()}`}
                style={{width: `${progress}%`}}
              ></div>
            </div>

            {/* Milestones */}
            {targets.map((target, index) => {
              const targetAchieved = current >= target.target;
              const milestoneColor = targetAchieved ? getProgressBarColor() : 'bg-danger';
              const lineColor = targetAchieved ? getProgressBarColor() : 'bg-danger';

              return (
                <div
                  key={index}
                  className='position-absolute d-flex flex-column align-items-center'
                  style={{
                    left: `${(target.target / maxTarget) * 99.1}%`,
                    transform: 'translateX(-50%)',
                  }}
                >
                  <div
                    className={`progress-bar ${lineColor}`}
                    style={{
                      height: '30px',
                      width: '3px',
                    }}
                  ></div>

                  {/* Milestone label positioned above the progress bar */}
                  <span
                    className={`badge ${milestoneColor}`}
                    style={{
                      marginTop: '-10px',
                      fontSize: '1.2rem',
                    }}
                  >
                    {currency ? `${target.incentive}%` : target.target}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
