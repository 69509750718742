import React, {useEffect, useState} from 'react';
import ProgressChart from './ProgressChart';
import LineChart from './LineChart';
import StaffProgressTable from './StaffProgressTable';
import QuarterlyInvoiceChart from './QuarterlyInvoiceChart';
import WeeklyDealStatusChart from './WeeklyDealStatusChart';
import {OrgChartComponent} from './OrgChartComponent';
import {
  getICInvoiceStats,
  getTeamDashboardStats,
  getICDealStats,
  getTeamList,
  getTeamDealStats,
  getTeamProgress,
} from './_requests';
import {useAuth} from '../../../app/modules/auth';
import {DashboardLoading} from './DashboardLoading';
import {InvoiceStats, TeamStatsResponse, DealStatsResponse, TeamProgressResponse} from './_models';
import {KTSVG} from '../../../_metronic/helpers';
import CreatableSelect from 'react-select/creatable';
import {SingleValue} from 'react-select';

interface TeamOption {
  value: string;
  label: string;
}

const DashboardWrapper: React.FC = () => {
  const {initAppData, auth} = useAuth();
  const allowedOperations = initAppData?.data.allowedOperations;

  const [teamList, setTeamList] = useState<string[] | null>(null);
  const [ICDashboardStatsData, setICDashboardStatsData] = useState<InvoiceStats | null>(null);
  const [teamDashboardStatsData, setTeamDashboardStatsData] = useState<TeamStatsResponse | null>(
    null
  );
  const [icDealStatsData, setICDealStatsData] = useState<DealStatsResponse | null>(null);

  const [selectedEmail, setSelectedEmail] = useState<string>(auth?.email || '');

  const [teamDealStats, settTeamDealStats] = useState<DealStatsResponse | null>(null);

  const [teamProgressData, setTeamProgressData] = useState<TeamProgressResponse | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log('selectedEmail ::: ', selectedEmail);
      try {
        const [ICStatsData, teamStatsData, dealStats, teamList, teamDealStats, teamProgressData] =
          await Promise.all([
            getICInvoiceStats(selectedEmail),
            getTeamDashboardStats(selectedEmail),
            getICDealStats(selectedEmail),
            getTeamList(selectedEmail),
            getTeamDealStats(selectedEmail),
            getTeamProgress(selectedEmail),
          ]);

        setICDashboardStatsData(ICStatsData);
        setTeamDashboardStatsData(teamStatsData);
        setICDealStatsData(dealStats);
        setTeamList(teamList);
        settTeamDealStats(teamDealStats);
        setTeamProgressData(teamProgressData);

        console.log('ICStatsData :: ', ICStatsData);
        console.log('teamStatsData :: ', teamStatsData);
        console.log('dealStats :: ', dealStats);
        console.log('teamList :: ', teamList);
        console.log('teamProgressData :: ', teamProgressData);

        console.log(
          'icDealStatsData?.weeklyDealStatusData ::: ',
          icDealStatsData?.weeklyDealStatusData
        );
      } catch (err) {
        setError('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedEmail]);

  if (!allowedOperations?.dashboard?.read) {
    return <>Dashboard</>;
  }

  if (loading) {
    return <DashboardLoading />;
  }

  if (error) {
    return (
      <div className='mb-lg-15 alert alert-danger'>
        <div className='alert-text font-weight-bold'>
          <KTSVG
            path='/media/icons/duotune/general/gen046.svg'
            className='svg-icon-1 svg-icon-danger'
          />{' '}
          {error}
        </div>
      </div>
    );
  }

  if (!ICDashboardStatsData || !teamDashboardStatsData) {
    return null;
  }

  const handleUserChange = (newValue: SingleValue<TeamOption>) => {
    setSelectedEmail(newValue?.value || '');
  };
  return (
    <div className='container-fluid'>
      {/* Team Member Selection */}
      {teamList && teamList.some((email) => email !== auth?.email) && (
        <div className='mb-4'>
          <label htmlFor='teamMemberSelect' className='form-label fw-bold'>
            Select Team Member:
          </label>
          <CreatableSelect
            id='teamMemberSelect'
            value={{
              value: selectedEmail,
              label: selectedEmail === auth?.email ? 'You' : selectedEmail,
            }}
            onChange={handleUserChange}
            // options={teamList.map((email) => ({
            //   value: email,
            //   label: email === auth?.email ? 'You' : email,
            // }))}

            options={Array.from(new Set(teamList)).map((email) => ({
              value: email,
              label: email === auth?.email ? 'You' : email,
            }))}
            isClearable
            placeholder='Search or select a team member...'
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral0: 'var(--kt-dark-light)', // Dropdown background color
                primary25: '#333333', // Hover color for selected option
                primary: '#1b1b29', // Border color for selected option
                neutral80: 'white', // Text color inside options
              },
            })}
          />
        </div>
      )}

      <h1 className='mb-4'>
        {selectedEmail === auth?.email
          ? `Welcome, ${auth?.given_name} ${auth?.family_name}!`
          : `Showing stats for ${selectedEmail}`}{' '}
        <span className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-8 py-1 px-3'>
          MONTHLY INCENTIVE SO FAR ${ICDashboardStatsData.incentiveData.monthlyIncentiveSoFar}
        </span>
      </h1>

      {/* Tickets and Profit Progress */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ProgressChart
            className='card-xl-stretch mb-5 mb-xl-8'
            title='Tickets Sold'
            description='Monthly Tickets Target'
            current={ICDashboardStatsData.incentiveData.tickets.ticketsSold}
            targets={ICDashboardStatsData.targets.ticket}
            nextTarget={ICDashboardStatsData.incentiveData.tickets.ticketsNeededForNextIncentive}
            currentIncentive={ICDashboardStatsData.incentiveData.tickets.currentTicketIncentive}
            nextIncentive={ICDashboardStatsData.incentiveData.tickets.nextTicketIncentive}
            type='Ticket'
          />
        </div>
        <div className='col-xl-6'>
          <ProgressChart
            className='card-xl-stretch mb-5 mb-xl-8'
            title='Profit Made'
            description='Monthly Profit Target'
            current={ICDashboardStatsData.incentiveData.profits.profitMade}
            targets={ICDashboardStatsData.targets.profit}
            currency={true}
            nextTarget={ICDashboardStatsData.incentiveData.profits.profitNeededForNextIncentive}
            currentIncentive={ICDashboardStatsData.incentiveData.profits.currentProfitIncentive}
            nextIncentive={ICDashboardStatsData.incentiveData.profits.nextProfitIncentive}
            type='Profit'
          />
        </div>
      </div>

      {/* Weekly Performance Charts */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <LineChart
            className='mb-5'
            title='Weekly Tickets Progress'
            labels={ICDashboardStatsData.weeklyPerformanceForMonth.tickets.map(
              (week) => `Week ${week.week}`
            )}
            dataPoints={ICDashboardStatsData.weeklyPerformanceForMonth.tickets.map(
              (week) => week.count
            )}
            color='success'
          />
        </div>
        <div className='col-xl-6'>
          <LineChart
            className='mb-5'
            title='Weekly Profit Progress'
            labels={ICDashboardStatsData.weeklyPerformanceForMonth.profits.map(
              (week) => `Week ${week.week}`
            )}
            dataPoints={ICDashboardStatsData.weeklyPerformanceForMonth.profits.map(
              (week) => week.amount
            )}
            color='info'
          />
        </div>
      </div>

      {/* Quarterly Performance Charts */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <QuarterlyInvoiceChart
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='info'
            chartHeight='350'
            title='Quarterly Profits'
            totalAmount={Object.values(teamDashboardStatsData.quarterlyProfitData)
              .map((value) => parseFloat(value as unknown as string) || 0) // Safely cast to string
              .reduce((a, b) => a + b, 0)} // Perform numeric addition
            labels={Object.keys(teamDashboardStatsData.quarterlyProfitData)}
            dataPoints={Object.values(teamDashboardStatsData.quarterlyProfitData)}
            isProfitChart={true} // Profits
            targets={teamDashboardStatsData.targets.profit}
          />
        </div>
        <div className='col-xl-6'>
          <QuarterlyInvoiceChart
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='success'
            chartHeight='350'
            title='Quarterly Tickets Sold'
            totalAmount={Object.values(teamDashboardStatsData.quarterlyTicketData)
              .map((value) => parseFloat(value as unknown as string) || 0) // Safely cast to string
              .reduce((a, b) => a + b, 0)} // Perform numeric addition
            labels={Object.keys(teamDashboardStatsData.quarterlyTicketData)}
            dataPoints={Object.values(teamDashboardStatsData.quarterlyTicketData)}
            isProfitChart={false} // Tickets
            targets={teamDashboardStatsData.targets.ticket}
          />
        </div>
      </div>

      {/* Quarterly Performance Charts */}
      <div className='row g-5 g-xl-8'>
        {icDealStatsData?.weeklyDealStatusData &&
          Object.keys(icDealStatsData?.weeklyDealStatusData).length > 0 && (
            <div className='col-xl-6'>
              <WeeklyDealStatusChart
                className='mb-5'
                labels={Object.keys(icDealStatsData.weeklyDealStatusData)}
                dataPoints={{
                  quote_sent: Object.values(icDealStatsData.weeklyDealStatusData).map(
                    (status) => status.quote_sent
                  ),
                  pending: Object.values(icDealStatsData.weeklyDealStatusData).map(
                    (status) => status.pending
                  ),
                  confirmation_sent: Object.values(icDealStatsData.weeklyDealStatusData).map(
                    (status) => status.confirmation_sent
                  ),
                  overdue: Object.values(icDealStatsData.weeklyDealStatusData).map(
                    (status) => status.overdue
                  ),
                  wff: Object.values(icDealStatsData.weeklyDealStatusData).map(
                    (status) => status.wff
                  ),
                }}
                chartColor='info'
                title='Weekly Deal Status Overview'
              />
            </div>
          )}

        {teamDealStats?.weeklyDealStatusData && (
          <div className='col-xl-6'>
            <WeeklyDealStatusChart
              className='mb-5'
              labels={Object.keys(teamDealStats.weeklyDealStatusData)}
              dataPoints={{
                quote_sent: Object.values(teamDealStats.weeklyDealStatusData).map(
                  (status) => status.quote_sent
                ),
                pending: Object.values(teamDealStats.weeklyDealStatusData).map(
                  (status) => status.pending
                ),
                confirmation_sent: Object.values(teamDealStats.weeklyDealStatusData).map(
                  (status) => status.confirmation_sent
                ),
                overdue: Object.values(teamDealStats.weeklyDealStatusData).map(
                  (status) => status.overdue
                ),
                wff: Object.values(teamDealStats.weeklyDealStatusData).map((status) => status.wff),
              }}
              chartColor='info'
              title='Weekly Deal Status Overview - Team'
            />
          </div>
        )}
      </div>

      {/* Staff Progress Table */}
      {teamProgressData?.teamProgressData && teamProgressData.teamProgressData.length > 0 && (
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-12'>
            <StaffProgressTable
              className='card-xl-stretch mb-5 mb-xl-8'
              staffData={teamProgressData.teamProgressData}
            />
          </div>
        </div>
      )}

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 mt-5'></div>
      <div className='card'>
        <div className='card-body p-lg-17'>
          <h1 className='mb-10'>Organizational Chart</h1>
          <div style={{width: '100%', height: '800px', overflow: 'auto'}}>
            <OrgChartComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export {DashboardWrapper};
