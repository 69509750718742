import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts';
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import {title} from 'process';

interface WeeklyDealStatusChartProps {
  className?: string;
  labels: string[];
  dataPoints: {
    quote_sent: number[];
    pending: number[];
    confirmation_sent: number[];
    overdue: number[];
    wff: number[];
  };
  chartColor?: string;
  title: string; // New title prop
}

const WeeklyDealStatusChart: React.FC<WeeklyDealStatusChartProps> = ({
  className = '',
  labels,
  dataPoints,
  chartColor = 'primary',
  title = '',
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

  const getChartOptions = (chartHeight: string): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-500');
    const borderColor = getCSSVariableValue('--kt-gray-200');

    return {
      series: [
        {name: 'Deals Sent', data: dataPoints.quote_sent},
        {name: 'Deals Pending', data: dataPoints.pending},
        {name: 'Confirmation Sent', data: dataPoints.confirmation_sent},
        {name: 'Overdue Deals', data: dataPoints.overdue},
        {name: 'WFF', data: dataPoints.wff},
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: {show: false},
      },
      plotOptions: {
        bar: {horizontal: false, columnWidth: '30%', borderRadius: 5},
      },
      legend: {show: true},
      dataLabels: {enabled: false},
      xaxis: {
        categories: labels,
        title: {
          text: 'Week No', // Title for the x-axis
          style: {
            color: labelColor,
            fontSize: '14px',
            fontWeight: 600,
          },
        },
        axisBorder: {show: false},
        axisTicks: {show: false},
        labels: {
          style: {colors: labelColor, fontSize: '12px'},
        },
      },
      yaxis: {
        labels: {
          style: {colors: labelColor, fontSize: '12px'},
        },
      },
      fill: {opacity: 1},
      tooltip: {
        style: {fontSize: '12px'},
        y: {
          formatter: (val: number) => val.toString(),
        },
        x: {
          formatter: (val) => `Week No ${val}`,
        },
      },
      colors: ['#ff7f0e', '#1f77b4', '#2ca02c', '#d62728'],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {lines: {show: true}},
      },
    };
  };

  const refreshChart = () => {
    if (!chartRef.current) return;

    const chartHeight = '350';
    const chart = new ApexCharts(chartRef.current, getChartOptions(chartHeight));
    chart.render();

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) chart.destroy();
    };
  }, [labels, dataPoints, mode]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body'>
        <div ref={chartRef} style={{height: '350px'}}></div>
      </div>
    </div>
  );
};

export default WeeklyDealStatusChart;
