// src/app/modules/apps/org-chart/_requests.ts

import {AxiosResponse} from 'axios';
import {initHttpClient} from '../../lib/_requests';
import {
  OrgChartResponse,
  InvoiceStatsResponse,
  InvoiceStats,
  TeamStatsResponse,
  DealStatsResponse,
  TeamListResponse,
  TeamProgressResponse,
  OrgChartNode,
} from './_models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_ORG_CHART_DATA_URL = `${API_URL}/org/chart?${Date.now()}&`;
const GET_IC_INVOICE_DASHBOARD_STATS_DATA_URL = `${API_URL}/dashboard/ic/invoice_stats?${Date.now()}`;
const GET_IC_DEALS_DASHBOARD_STATS_DATA_URL = `${API_URL}/dashboard/ic/deal_stats?${Date.now()}&`;
const GET_TEAM_DASHBOARD_STATS_DATA_URL = `${API_URL}/dashboard/team/invoice_stats?${Date.now()}&`;
const GET_TEAM_DEALS_DASHBOARD_STATS_DATA_URL = `${API_URL}/dashboard/team/deal_stats?${Date.now()}&`;
const GET_TEAM_PROGRESS_URL = `${API_URL}/dashboard/team/progress?${Date.now()}`;

const GET_TEAM_LIST_URL = `${API_URL}/team/list?${Date.now()}`;

const handleAxiosError = (error: any) => {
  console.error('API Error:', error);
  if (error.response) {
    if (error.response.status === 500) {
      // Handle 500 error if needed
    }
    return error.response.data;
  } else if (error.request) {
    return error.request;
  } else {
    return error.message;
  }
};

export const getOrgChartData = (): Promise<OrgChartNode> => {
  return initHttpClient()
    .get(GET_ORG_CHART_DATA_URL)
    .then((d: AxiosResponse<OrgChartResponse>) => d.data) // Fetches OrgChartResponse
    .then((d: OrgChartResponse) => d.data) // Extracts OrgChartNode from OrgChartResponse
    .catch(handleAxiosError);
};

export const getICInvoiceStats = (staff_email?: string): Promise<InvoiceStats> => {
  // Build query parameters if staff_email is provided
  const params = staff_email ? {staff_email} : undefined;

  return initHttpClient()
    .get(GET_IC_INVOICE_DASHBOARD_STATS_DATA_URL, {params})
    .then((response: AxiosResponse<InvoiceStatsResponse>) => response.data)
    .then((response: InvoiceStatsResponse) => response.data) // Extracts OrgChartNode from OrgChartResponse
    .catch(handleAxiosError);
};

export const getICDealStats = (staff_email?: string): Promise<DealStatsResponse> => {
  // Build query parameters if staff_email is provided
  const params = staff_email ? {staff_email} : undefined;

  return initHttpClient()
    .get(GET_IC_DEALS_DASHBOARD_STATS_DATA_URL, {params})
    .then((response: AxiosResponse<DealStatsResponse>) => response.data)
    .catch(handleAxiosError);
};

// Fetches data from the dashboard/ic/stats endpoint
export const getTeamDashboardStats = (staff_email?: string): Promise<TeamStatsResponse> => {
  const params = staff_email ? {staff_email} : undefined;

  return initHttpClient()
    .get(GET_TEAM_DASHBOARD_STATS_DATA_URL, {params})
    .then((response: AxiosResponse<TeamStatsResponse>) => response.data)
    .catch(handleAxiosError);
};

export const getTeamDealStats = (staff_email?: string): Promise<DealStatsResponse> => {
  const params = staff_email ? {staff_email} : undefined;
  return initHttpClient()
    .get(GET_TEAM_DEALS_DASHBOARD_STATS_DATA_URL, {params})
    .then((response: AxiosResponse<DealStatsResponse>) => response.data)
    .catch(handleAxiosError);
};

// Fetches the team list from the backend
export const getTeamList = (staff_email?: string): Promise<string[]> => {
  const params = staff_email ? {staff_email} : undefined;
  return initHttpClient()
    .get(GET_TEAM_LIST_URL, {params})
    .then((response: AxiosResponse<TeamListResponse>) => response.data)
    .then((response: TeamListResponse) => response.data)
    .catch(handleAxiosError);
};

export const getTeamProgress = (staff_email?: string): Promise<TeamProgressResponse> => {
  const params = staff_email ? {staff_email} : undefined;
  return initHttpClient()
    .get(GET_TEAM_PROGRESS_URL, {params})
    .then((response: AxiosResponse<TeamProgressResponse>) => response.data)
    .catch(handleAxiosError);
};
